import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Async } from 'modules/Users/Students/StudentLicenseSlice';
import { Actions } from 'modules/Users/Students/StudentSlice';
import { defaultErrorToast } from 'modules/Utils';
import { getEnabledTestsFromStudent } from 'modules/Users/Students/Utils';
import { useBaseTests } from 'modules/BaseTests/Hooks';
import { renameObjectKey } from 'modules/Utils/RenameObjectKey';
import ModalStudentLicense from '../../Students/ModalStudentLicense';
import { useAuth } from 'modules/Auth/Hooks';
import {
  FormGroup,
  Label,
  SelectAutocomplete,
  Button,
  Toast,
} from 'modules/Core/Common';
import { EditIcon, DeleteIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './styles';

const UserLicenses = ({ user, columnsSize }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [studentLicense, setStudentLicense] = useState({});
  const [selectedTest, setSelectedTest] = useState('');
  const [testsOptions, setTestsOptions] = useState([]);
  const { isSuperAdmin } = useAuth();

  const dispatch = useDispatch();

  const {
    state: { data: baseTestsState },
  } = useBaseTests();

  useEffect(() => {
    if (!modalOpen) setStudentLicense({});
  }, [modalOpen]);

  useEffect(() => {
    prepareTestsToSelect(user, baseTestsState?.baseTests);
  }, [baseTestsState]);

  const isTestsOptionsEmpty = !testsOptions?.length;
  const userHasLicenses = !!user?.licenses?.length;

  const prepareTestsToSelect = (updUser, testsList) => {
    const userObj = updUser || user;
    const testsArray = testsList || baseTestsState?.baseTests;

    if (!testsArray?.length) return;

    let combinedTestsOptions = getEnabledTestsFromStudent(
      userObj,
      testsArray
    ).filter((testOption) => {
      const licenseExists = userObj?.licenses?.find(
        (license) =>
          (license?.test?.id || license?.custom_test?.id) === testOption?.id &&
          (license?.test?.tag || license?.custom_test?.tag) === testOption?.tag
      );

      return licenseExists ? false : true;
    });

    combinedTestsOptions = renameObjectKey(
      combinedTestsOptions,
      'id',
      'testId'
    );
    combinedTestsOptions = renameObjectKey(combinedTestsOptions, 'tag', 'id');

    setTestsOptions([...combinedTestsOptions]);
  };

  const removeTestOptionFromList = (testTag) => {
    setTestsOptions(
      testsOptions.filter((testOption) => testOption.tag !== testTag)
    );
    setSelectedTest('');
  };

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function handleCreateObjectStudentLicense() {
    if (!selectedTest) return;

    const testSelected = testsOptions.find((test) => test?.id === selectedTest);
    let obj = {};

    if (testSelected?.isCustomTest) {
      obj = {
        custom_test: {
          id: testSelected.testId,
          name: testSelected.name,
        },
        student_id: user.id,
      };
    } else {
      obj = {
        test: {
          id: testSelected.testId,
          name: testSelected.name,
        },
        student_id: user.id,
      };
    }

    setStudentLicense(obj);
    toggleModal();
  }

  function handleEditStudentLicense(license) {
    setStudentLicense(license);
    toggleModal();
  }

  function handleDeleteStudentLicense(licenseId) {
    if (window.confirm('Are you sure?')) {
      const action = Async.deleteStudentLicense({
        id: licenseId,
        onSuccess: () => {
          const updUser = {
            ...user,
            licenses: user.licenses.filter(
              (license) => +license.id !== +licenseId
            ),
          };
          dispatch(Actions.updateStudentOnList(updUser));
          prepareTestsToSelect(updUser);
          Toast(
            i18n.t(
              'success-messages.successfully-toast.removed-student-license'
            ),
            'success'
          );
        },
        onError: (e) =>
          defaultErrorToast(
            i18n.t(
              'errors.error-sorry-an-error-occurred-during.delete-student-license'
            )
          )(e),
      });
      dispatch(action);
    }
  }

  function handleSubmit(data) {
    // Update student license
    if (data.id) {
      const action = Async.updateStudentLicense({
        data,
        onSuccess: (response) => {
          const updatedLicense = response.data.content;

          const updUser = {
            ...user,
            licenses: user.licenses.map((license) => {
              if (+license.id === +updatedLicense.id) {
                return updatedLicense;
              }

              return license;
            }),
          };

          Toast(
            i18n.t(
              'success-messages.successfully-toast.uploaded-student-license'
            ),
            'success'
          );
          toggleModal();
          dispatch(Actions.updateStudentOnList(updUser));
        },
        onError: (e) =>
          defaultErrorToast(
            i18n.t(
              'errors.error-sorry-an-error-occurred-during.update-student-license'
            )
          )(e),
      });
      dispatch(action);
      // Create student license
    } else {
      const action = Async.addStudentLicense({
        data,
        onSuccess: (response) => {
          const createdLicense = response.data.content;
          const userLicenses = user?.licenses || [];

          const updUser = {
            ...user,
            licenses: [...userLicenses, createdLicense],
          };

          Toast(
            i18n.t(
              'success-messages.successfully-toast.created-student-license'
            ),
            'success'
          );
          toggleModal();
          dispatch(Actions.updateStudentOnList(updUser));
          removeTestOptionFromList(
            createdLicense?.test?.tag || createdLicense?.custom_test?.tag
          );
        },
        onError: (e) =>
          defaultErrorToast(
            i18n.t(
              'errors.error-sorry-an-error-occurred-during.create-student-license'
            )
          )(e),
      });
      dispatch(action);
    }
  }

  return (
    <>
      <ModalStudentLicense
        studentId={user.id}
        studentLicense={studentLicense}
        isOpen={modalOpen}
        setIsOpen={toggleModal}
        onSubmit={handleSubmit}
      />
      <S.AnimatedTr>
        <S.LicensesContainer colSpan={columnsSize}>
          <S.LicenseRow>
            <S.LicenseCol>
              <S.SelectContainer>
                <FormGroup>
                  <Label
                    htmlFor="testId"
                    style={{
                      opacity: isTestsOptionsEmpty ? 0.5 : 1,
                    }}
                  >
                    {i18n.t(
                      'modules.users.students.table.license-details.select-test'
                    )}
                  </Label>

                  <S.Select>
                    <SelectAutocomplete
                      id="test_search_id"
                      placeholder={
                        isTestsOptionsEmpty
                          ? ''
                          : i18n.t(
                              'modules.users.students.table.license-details.select-test'
                            )
                      }
                      disabled={isTestsOptionsEmpty}
                      searchPlaceholder="Search test"
                      togglClassName="toggl-input"
                      options={testsOptions}
                      value={selectedTest ? [selectedTest] : []}
                      onChangeSelect={(id) => setSelectedTest(id)}
                    />
                  </S.Select>
                </FormGroup>
                <Button
                  small
                  onClick={handleCreateObjectStudentLicense}
                  style={{
                    opacity: isTestsOptionsEmpty ? 0.5 : 1,
                    cursor: isTestsOptionsEmpty ? 'not-allowed' : 'pointer',
                  }}
                >
                  {i18n.t(
                    'modules.users.students.table.table-buttons.add-license'
                  )}
                </Button>
              </S.SelectContainer>
            </S.LicenseCol>
            {userHasLicenses && (
              <S.LicenseSection>
                <S.LicenseCol style={{ paddingLeft: 0 }}>
                  <Label>{i18n.t('common-words.licenses')}</Label>
                  <S.LicensesList>
                    {user.licenses.map((license) => (
                      <S.LicensesListItem
                        key={license?.test?.id || license?.custom_test?.id}
                      >
                        <S.LicenseTitle>
                          {license?.test?.name || license?.custom_test?.name}
                        </S.LicenseTitle>
                        <S.LicenseActions>
                          <S.Button
                            onClick={() => handleEditStudentLicense(license)}
                          >
                            <EditIcon color="white" />
                          </S.Button>
                          {isSuperAdmin ? (
                            <S.Button
                              onClick={() =>
                                handleDeleteStudentLicense(license.id)
                              }
                            >
                              <DeleteIcon color="white" />
                            </S.Button>
                          ) : null}
                        </S.LicenseActions>
                      </S.LicensesListItem>
                    ))}
                  </S.LicensesList>
                </S.LicenseCol>

                <S.LicenseCol style={{ paddingLeft: 0 }}>
                  <Label>
                    {i18n.t(
                      'modules.users.students.table.license-details.license-number'
                    )}
                  </Label>
                  <S.LicensesList>
                    {user.licenses.map((license) => (
                      <S.LicensesNumberItem key={license?.id}>
                        <S.LicenseTitle>{license?.license_key}</S.LicenseTitle>
                      </S.LicensesNumberItem>
                    ))}
                  </S.LicensesList>
                </S.LicenseCol>
              </S.LicenseSection>
            )}
          </S.LicenseRow>
        </S.LicensesContainer>
      </S.AnimatedTr>
    </>
  );
};

UserLicenses.defaultProps = {
  user: {
    id: '',
    licenses: [],
  },
  columnsSize: 1,
};

UserLicenses.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    licenses: PropTypes.array.isRequired,
    custom_tests: PropTypes.array,
    whitelabel_id: PropTypes.number,
  }),
  columnsSize: PropTypes.number.isRequired,
};

export default UserLicenses;
