import React from 'react';
import cogoToast from 'cogo-toast';
import * as S from './styles';
import { Icon } from 'modules/Core/Common';
import './styles.css';

const getClassByType = (type) => {
  switch (type) {
    case 'success':
      return {
        type: 'success',
        title: 'Success!',
        icon: 'check',
        color: '#1BD171',
      };
    case 'warn':
      return {
        type: 'warn',
        title: 'Warning!',
        icon: 'warning',
        color: '#FBAB34',
      };
    case 'info':
      return {
        type: 'info',
        title: 'Info!',
        icon: 'info',
        color: '#0075EA',
      };
    case 'error':
      return {
        type: 'error',
        title: 'Error!',
        icon: 'close',
        color: '#EC3237',
      };
    case 'connected':
      return {
        type: 'success',
        title: 'Is connected',
        icon: 'online',
        color: '#1BD171',
      };
    case 'reconnecting':
      return {
        type: 'warn',
        title: 'Attempting to reconnect...',
        icon: 'info',
        color: '#FBAB34',
      };
    case 'offline':
      return {
        type: 'error',
        title: 'No connection',
        icon: 'offline',
        color: '#EC3237',
      };
    default:
      return '';
  }
};
// TODO why is that shit breaking the Unit tests?
const ToastComponent = (
  message,
  type,
  position = 'bottom-right',
  hideAfter = 5,
  title
) => {
  const { hide } =
    !process.env.CI &&
    cogoToast[getClassByType(type).type](message, {
      onClick: () => hide(),
      heading: title || getClassByType(type).title,
      hideAfter,
      position,
      bar: { size: '4px', style: 'solid', color: getClassByType(type).color },
      renderIcon: () => (
        <S.IconBlock type={type === 'reconnecting' ? 'loading' : type}>
          <Icon name={`${getClassByType(type).icon}`} />

          {type === 'reconnecting' && (
            <Icon name="loading reconnecting-loading" />
          )}
        </S.IconBlock>
      ),
    });
};

export default ToastComponent;
