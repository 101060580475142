import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  ANTIFRAUD_SESSIONS_URL,
  ANTIFRAUD_SESSIONS_BY_ID_EVENTS_URL,
  ANTIFRAUD_SESSIONS_EXPORT_URL,
} from 'modules/Api/Routes';
import { getISOStringFromStringDate } from 'modules/Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

let cancelToken;

const startDate = getUTCDefaultStartDate();
const endDate = getUTCDefaultEndDate();

const initialState = {
  loading: false,
  error: null,
  exportFilter: {
    incidentLevel: 'all',
    reviewed: 'all',
    integration: 'all',
    startDate: startDate,
    endDate: endDate,
    extension: 'csv',
    link: '',
    loading: false,
  },
  data: {
    page: 1,
    perPage: 10,
    search: '',
    incidentLevel: '',
    reviewed: '',
    integration: '',
    startDate: startDate,
    endDate: endDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  antifraudData: {
    session: {
      incident_level: null,
      antifraud_comments: null,
    },
  },
  sessionEvents: {
    loading: false,
    loadingNextEvents: false,
    error: null,
    data: [],
    page: 1,
    perPage: 15,
    nextPage: null,
    except: 'UI_EVENT',
  },
  updateAntifraudData: {
    loading: false,
    id: '',
  },
  isDeleting: false,
};

const antifraudPluginSlice = createSlice({
  name: 'antifraudPlugin',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestAntifraudPlugin: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestUpdateComments: (state) => {
      state.error = null;
    },
    requestAntifraudPluginEvents: (state) => {
      state.sessionEvents.loading = true;
      state.sessionEvents.data = [];
      state.sessionEvents.error = null;
    },
    requestAntifraudPluginNextEvents: (state) => {
      state.sessionEvents.loadingNextEvents = true;
      state.sessionEvents.error = null;
    },
    requestUpdateAntifraudPlugin: (state, action) => {
      state.updateAntifraudData.loading = true;
      state.updateAntifraudData.id = action.payload.id;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changeSessionEventsPage: (state, action) => {
      state.sessionEvents.page = action.payload;
    },
    changeSessionEventsNextPage: (state, action) => {
      state.sessionEvents.nextPage = action.payload;
    },
    changeAntifraudPluginFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action.payload?.search || '';
      state.data.startDate = action.payload?.start_date || state.data.startDate;
      state.data.endDate = action.payload?.end_date || state.data.endDate;
      state.data.incidentLevel = action.payload?.incident_level || '';
      state.data.reviewed = action.payload?.reviewed || '';
      state.data.integration = action.payload?.external_application_id || '';

      // update export filter
      state.exportFilter.startDate = state.data.startDate;
      state.exportFilter.endDate = state.data.endDate;
      state.exportFilter.incidentLevel = state.data.incidentLevel || 'all';
      state.exportFilter.reviewed = state.data.reviewed || 'all';
      state.exportFilter.integration = state.data.integration || 'all';
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    changePluginSessionIncidentLevel: (state, action) => {
      state.antifraudData.session.incident_level = action.payload;
    },
    changePluginSessionIntegration: (state, action) => {
      state.antifraudData.session.external_application_id = action.payload;
    },
    changePluginSessionComments: (state, action) => {
      state.antifraudData.session.antifraud_comments = action.payload;
    },
    changePluginSessionReviewed: (state, action) => {
      state.antifraudData.session.reviewed = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success antifraudPlugins list response
     */
    receiveAntifraudPluginList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        sessions: action.payload.sessions,
        total: action.payload.total_items,
      };
    },
    receiveAntifraudPluginSession: (state, action) => {
      state.loading = false;
      state.antifraudData = action.payload;
    },
    receiveAntifraudPluginEventsList: (state, action) => {
      state.sessionEvents.loading = false;
      state.sessionEvents.data = action.payload.events;
      state.sessionEvents.nextPage = action.payload.next_page;
    },
    receiveAntifraudPluginNextEventsList: (state, action) => {
      state.sessionEvents.loadingNextEvents = false;
      state.sessionEvents.nextPage = action.payload.next_page;
      state.sessionEvents.data = [
        ...state.sessionEvents.data,
        ...action.payload.events,
      ];
    },
    receiveUpdateAntifraudPluginRequestSuccess: (state) => {
      state.updateAntifraudData.loading = false;
      state.loading = false;
      state.updateAntifraudData.id = '';
    },
    clearAntifraudPluginList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        startDate,
        endDate,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearAntifraudPluginFilters: (state) => {
      state.data.startDate = getUTCDefaultStartDate();
      state.data.endDate = getUTCDefaultEndDate();
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.incidentLevel = '';
      state.data.reviewed = '';
      state.data.integration = '';
    },
    /**
     * receive an error response
     */
    receiveAntifraudPluginError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveAntifraudPluginEventsError: (state, action) => {
      state.sessionEvents.loading = false;
      state.sessionEvents.error = action.payload;
    },
    changeAntifraudPluginPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeAntifraudPluginSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeAntifraudPluginReviewed: (state, action) => {
      state.antifraudData.session.reviewed = action.payload;
    },
    updateAntifraudPluginReviewedField: (state, action) => {
      const updatedAntifraudPlugin = action.payload;
      const antifraudObj = state.data?.sessions?.find(
        (antifraud) => antifraud.id === updatedAntifraudPlugin.id
      );

      if (!antifraudObj) return;

      antifraudObj.reviewed = updatedAntifraudPlugin.reviewed;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link = action?.payload?.link;
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
    resetExportValues: (state) => {
      const { incidentLevel, reviewed, integration } = state.data;
      state.exportFilter = {
        incidentLevel: incidentLevel ? incidentLevel : 'all',
        reviewed: reviewed ? reviewed : 'all',
        integration: integration ? integration : 'all',
        startDate: state.data.startDate,
        endDate: state.data.endDate,
        extension: 'csv',
        loading: false,
      };
    },
  },
});

const Actions = antifraudPluginSlice.actions;

const Selectors = {
  fetchListData: (state) => state.antifraudPlugin,
  antifraudPluginLoading: ({ antifraudPlugin: { loading } }) => ({ loading }),
};

const Async = {
  fetchAntifraudPluginList: () => async (dispatch, getState) => {
    const {
      antifraudPlugin: {
        data: {
          page,
          perPage,
          search,
          incidentLevel,
          reviewed,
          integration,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestAntifraudPlugin());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: ANTIFRAUD_SESSIONS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          incident_level: incidentLevel || undefined,
          reviewed: reviewed || undefined,
          external_application_id: integration || undefined,
          sort: sortType,
          sort_by: sortBy,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
        },
      });

      action = Actions.receiveAntifraudPluginList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveAntifraudPluginError(e.message);
      }
    }

    action && dispatch(action);
  },

  getAntifraudPluginById:
    ({ id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestAntifraudPlugin());

      try {
        const response = await request({
          method: 'GET',
          url: `${ANTIFRAUD_SESSIONS_URL}/${id}`,
        });

        action = Actions.receiveAntifraudPluginSession(response.data.content);
      } catch (e) {
        action = Actions.receiveAntifraudPluginError(e.message);
      }

      dispatch(action);
    },

  fetchAntifraudPluginEvents:
    ({ id }) =>
    async (dispatch, getState) => {
      const {
        antifraudPlugin: {
          sessionEvents: { page, perPage, except },
        },
      } = getState();

      let action;

      dispatch(Actions.requestAntifraudPluginEvents());

      try {
        const response = await request({
          method: 'GET',
          url: ANTIFRAUD_SESSIONS_BY_ID_EVENTS_URL(id),
          params: {
            page,
            paginates_per: perPage,
            except,
          },
        });

        action = Actions.receiveAntifraudPluginEventsList(
          response.data.content
        );
      } catch (e) {
        action = Actions.receiveAntifraudPluginEventsError(e.message);
      }

      dispatch(action);
    },

  fetchAntifraudPluginNextEvents: () => async (dispatch, getState) => {
    const {
      antifraudPlugin: {
        antifraudData: { session },
        sessionEvents: { except },
      },
    } = getState();

    let action;

    dispatch(Actions.requestAntifraudPluginNextEvents());

    try {
      const response = await request({
        method: 'GET',
        url: ANTIFRAUD_SESSIONS_BY_ID_EVENTS_URL(session.id),
        params: {
          except,
        },
      });

      action = Actions.receiveAntifraudPluginNextEventsList(
        response.data.content
      );
    } catch (e) {
      action = Actions.receiveAntifraudPluginEventsError(e.message);
    }

    dispatch(action);
  },

  deleteAntiFraud:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${ANTIFRAUD_SESSIONS_URL}/${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  updateAntifraudPlugin:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        antifraudPlugin: {
          antifraudData: { session },
        },
      } = getState();

      dispatch(Actions.requestUpdateComments());

      try {
        const response = await request({
          method: 'PUT',
          url: `${ANTIFRAUD_SESSIONS_URL}/${session.id}`,
          data: {
            id: session.id,
            comments: session.antifraud_comments,
            incident_level: session.incident_level,
            external_application_id: session.external_application_id,
            reviewed:
              typeof session.reviewed === 'boolean'
                ? session.reviewed
                : session.reviewed > 1,
          },
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  updateAntifraudPluginWithoutLoading:
    ({ data, onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        antifraudPlugin: {
          antifraudData: { session },
        },
      } = getState();

      data?.id &&
        dispatch(Actions.requestUpdateAntifraudPlugin({ id: data?.id }));

      try {
        const response = await request({
          method: 'PUT',
          url: `${ANTIFRAUD_SESSIONS_URL}/${data?.id || session?.id}`,
          data: data || {
            id: session.id,
            comments: session.antifraud_comments,
            incident_level: session.incident_level,
            external_application_id: session.external_application_id,
            reviewed:
              typeof session.reviewed === 'boolean'
                ? session.reviewed
                : session.reviewed > 1,
          },
        });

        dispatch(Actions.receiveUpdateAntifraudPluginRequestSuccess());
        dispatch(
          Actions.updateAntifraudPluginReviewedField(response.data.content)
        );
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        antifraudPlugin: {
          data: { search },
          exportFilter: {
            startDate,
            endDate,
            incidentLevel,
            reviewed,
            integration,
            extension,
          },
        },
      } = getState();

      let action;
      const isEmptyOrAll = (value) => !value || value === 'all';
      const incident_level = isEmptyOrAll(incidentLevel) ? null : incidentLevel;
      const reviewedParam = isEmptyOrAll(reviewed) ? null : reviewed;
      const external_application_id = isEmptyOrAll(integration)
        ? null
        : integration;

      dispatch(Actions.requestExportLinks());

      const data = {
        file_type: extension,
        start_date: startDate,
        end_date: endDate,
        incident_level,
        reviewed: reviewedParam,
        external_application_id,
        search,
      };

      try {
        const response = await request({
          method: 'POST',
          url: ANTIFRAUD_SESSIONS_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const { reducer } = antifraudPluginSlice;

export { reducer, Actions, Async, Selectors };
