import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import camelcase from 'camelcase';
import i18n from 'i18next';

import { Async } from 'modules/Licenses/LicensesSlice';

import emptyDataIcon from 'assets/layout/empty-data/test-icon.svg';

import { defaultErrorToast } from 'modules/Utils';
import { useNonInitialEffect } from 'modules/Core/Hooks';
import { fieldNameToKey, fieldNamesMap } from './../LicenseSchema';

import { Row, Col, Title, FormGroup, Label, Number } from 'modules/Core/Common';
import { LicenseAmountSkeleton } from './LicenseAmountSkeleton.component';
import { CEFR_NAME, MCER_NAME } from 'constants';
import * as S from './LicenseAmount.styles';

const labelTooltipStyle = {
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const LicenseAmount = ({
  studentId,
  license,
  showLicensesAvailable,
  testType,
  onCustomCategoriesAmountChange,
  handleChangeValue,
}) => {
  const [amountFields, setAmountFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [availablesPluralText, setAvailablesPluralText] = useState(
    i18n.t('modules.licenses.licenses-available')
  );
  const fullTestNames = [CEFR_NAME, MCER_NAME];

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (license?.testId || license?.customTestId) {
      // licenseId from student license and license?.id from licenses module
      loadTestAmounts(license?.licenseId || license?.id);
    }
    if (license.availableAmount != 1) {
      setAvailablesPluralText(i18n.t('modules.licenses.licenses-available'));
    }
  }, [license.testId, license.customTestId]);

  useNonInitialEffect(() => {
    resetAmounts();
  }, [license.testId, license.customTestId]);

  const onChangeAmountField = (value, fieldName, categoryId) => {
    if (categoryId) {
      return handleCustomCategoryChange({
        amount: value,
        category_id: categoryId,
      });
    }
    const fieldKey = fieldNameToKey(fieldName);
    handleChangeValue(fieldKey, value);
  };

  const setDefaultAmountsAfterLoading = (values) => {
    Object.keys(values).forEach((content) => {
      values[content].forEach((item) => {
        if (item.category_id) {
          return handleCustomCategoryChange({
            amount: item.amount,
            category_id: item.category_id,
          });
        }
        const fieldKey = fieldNameToKey(item.field_name);
        return handleChangeValue(fieldKey, item.amount);
      });
    });
  };

  const resetAmounts = () => {
    Object.keys(fieldNamesMap).forEach((fieldName) => {
      handleChangeValue(fieldNamesMap[fieldName], 0);
    });
    if (onCustomCategoriesAmountChange) onCustomCategoriesAmountChange([]);
  };

  const loadTestAmounts = (id) => {
    setLoading(true);
    const action = Async.getLicenseTestAmount({
      id,
      studentId,
      testId: license.testId || license.customTestId,
      typeTest: testType,
      selectedGroup: !!license.groupId,
      onSuccess: ({ data }) => {
        setAmountFields(data.content);
        setDefaultAmountsAfterLoading(data.content);
        setLoading(false);
      },
      onError: (e) => {
        defaultErrorToast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.get-new-license-test-amount'
          )
        )(e);
        setLoading(false);
      },
    });

    dispatch(action);
  };

  const getLicenseAmountFieldValue = (fieldName, categoryId) => {
    if (categoryId) {
      const category = license?.customCategoriesAmount?.find(
        (category) => +category?.category_id === +categoryId
      );

      return category?.amount || 0;
    }

    return license?.[camelcase(fieldName)];
  };

  const handleCustomCategoryChange = (category) => {
    const categoryExists = license?.customCategoriesAmount?.some(
      (currentCategory) =>
        +currentCategory?.category_id === +category?.category_id
    );

    if (categoryExists) {
      const updatedCategoriesAmount = license?.customCategoriesAmount?.map(
        (currentCategory) => {
          if (+currentCategory?.category_id === +category?.category_id) {
            return category;
          }

          return currentCategory;
        }
      );

      return onCustomCategoriesAmountChange(updatedCategoriesAmount);
    }

    onCustomCategoriesAmountChange([
      ...license.customCategoriesAmount,
      category,
    ]);
  };

  if (loading) {
    return (
      <>
        <S.FormHeader>
          <Title size="quartenary" tag="h2" margin="15px 0 0 0">
            {i18n.t('modules.licenses.license-amount')}
          </Title>
        </S.FormHeader>

        <LicenseAmountSkeleton />
      </>
    );
  }

  return (
    <>
      <S.FormHeader>
        <Title size="quartenary" tag="h2" margin="15px 0 0 0">
          {i18n.t('modules.licenses.license-amount')}
        </Title>
        {showLicensesAvailable && license?.availableAmount !== 0 && (
          <span>
            <strong>{license?.availableAmount}</strong> {availablesPluralText}
          </span>
        )}
      </S.FormHeader>

      {!license?.testId && !license?.customTestId && (
        <S.EmptyData>
          <img src={emptyDataIcon} alt="empty-data-test-icon" />
          <div>
            <p>
              {i18n.t(
                'modules.licenses.license-forms.license-amount.empty-data.message'
              )}
            </p>
            <span>
              {i18n.t(
                'modules.licenses.license-forms.license-amount.empty-data.warning'
              )}
            </span>
          </div>
        </S.EmptyData>
      )}

      {!!amountFields?.simulation?.length && (
        <Title size="default" tag="h6" style={{ margin: '32px 0 16px 0' }}>
          {i18n.t('common-words.tests')}
        </Title>
      )}

      <Row>
        {amountFields?.simulation &&
          amountFields.simulation.map((field, index) => (
            <Col xs={12} sm={2} key={index}>
              <FormGroup>
                <Label
                  showTooltip={field.name.length > 17}
                  style={labelTooltipStyle}
                >
                  {fullTestNames.includes(field.name)
                    ? 'Full Test'
                    : field.name}
                </Label>
                <Number
                  id={camelcase(field.field_name)}
                  name={camelcase(field.field_name)}
                  type="number"
                  onChange={(value) =>
                    onChangeAmountField(
                      value,
                      field.field_name,
                      field.category_id
                    )
                  }
                  value={getLicenseAmountFieldValue(
                    field.field_name,
                    field.category_id
                  )}
                />
              </FormGroup>
            </Col>
          ))}
      </Row>

      {!!amountFields?.practice?.length && (
        <Title size="default" tag="h6" style={{ margin: '0 0 16px 0' }}>
          {i18n.t('common-words.practice')}
        </Title>
      )}

      <Row>
        {amountFields?.practice &&
          amountFields.practice.map((field, index) => (
            <Col xs={12} sm={2} key={index}>
              <FormGroup>
                <Label
                  showTooltip={field.name.length > 17}
                  style={labelTooltipStyle}
                >
                  {field.name}
                </Label>
                <Number
                  id={camelcase(field.field_name)}
                  name={camelcase(field.field_name)}
                  type="number"
                  onChange={(value) =>
                    onChangeAmountField(
                      value,
                      field.field_name,
                      field.category_id
                    )
                  }
                  value={getLicenseAmountFieldValue(
                    field.field_name,
                    field.category_id
                  )}
                />
              </FormGroup>
            </Col>
          ))}
      </Row>

      {!!amountFields?.others?.filter((field) => !field.name.includes('Amount'))
        .length && (
        <Title size="default" tag="h6" style={{ margin: '0 0 16px 0' }}>
          {i18n.t('common-words.others')}
        </Title>
      )}

      <Row>
        {amountFields?.others &&
          amountFields.others
            .filter((field) => field.name !== i18n.t('common-words.amount'))
            .map((field, index) => (
              <Col xs={12} sm={2} key={index}>
                <FormGroup>
                  <Label
                    showTooltip={field.name.length > 17}
                    style={labelTooltipStyle}
                  >
                    {field.name}
                  </Label>
                  <Number
                    id={camelcase(field.field_name)}
                    name={camelcase(field.field_name)}
                    type="number"
                    onChange={(value) =>
                      onChangeAmountField(
                        value,
                        field.field_name,
                        field.category_id
                      )
                    }
                    value={getLicenseAmountFieldValue(
                      field.field_name,
                      field.category_id
                    )}
                  />
                </FormGroup>
              </Col>
            ))}
      </Row>
    </>
  );
};

LicenseAmount.defaultProps = {
  showLicensesAvailable: true,
};

LicenseAmount.propTypes = {
  studentId: PropTypes.number,
  license: PropTypes.object,
  showLicensesAvailable: PropTypes.bool,
  testType: PropTypes.string,
  onCustomCategoriesAmountChange: PropTypes.func,
  handleChangeValue: PropTypes.func,
};
