import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  NOTIFICATIONS_URL,
  NOTIFICATION_BY_ID_URL,
  NOTIFICATIONS_CREATE_URL,
  NOTIFICATIONS_UPDATE_URL,
  NOTIFICATIONS_DELETE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { parseNotificationTest } from 'modules/Settings/Notifications/NotificationsUtils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  group: {
    data: [],
    loading: false,
    error: false,
  },
  data: {
    startDate: getUTCDefaultStartDate(),
    endDate: getUTCDefaultEndDate(),
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
    type: null,
    notificationType: null,
    status: null,
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestNotifications: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    requestLanguages: (state) => {
      state.languages = {
        ...state.languages,
        loading: true,
      };
    },
    requestTimezones: (state) => {
      state.timezones = {
        ...state.timezones,
        loading: true,
      };
    },
    receiveTimezonesList: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveTimezonesError: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveLanguagesError: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
      };
      state.error = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success notifications list response
     */
    receiveNotificationsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        notifications: action.payload.notifications,
        total: action.payload.total_items,
      };
    },
    clearNotificationsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
        type: null,
        notificationType: null,
        status: null,
        startDate: getUTCDefaultStartDate(),
        endDate: getUTCDefaultEndDate(),
      };
    },
    /**
     * receive a success language list response
     */
    receiveLanguagesList: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
        data: action.payload.content,
      };
    },
    /**
     * receive an error response
     */
    receiveNotificationsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeNotificationsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeNotificationsSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
      state.data.type = action.payload.type;
      state.data.notificationType = action.payload.notificationType;
      state.data.status = action.payload.status;
      state.data.startDate = action.payload.start_date;
      state.data.endDate = action.payload.end_date;
    },
    updateNotificationsOnList: (state, action) => {
      const updated = action.payload;
      const index = state.data.students.findIndex(
        (student) => student.id === updated.id
      );
      if (index !== -1) state.data.students.splice(index, 1, updated);
    },
    changeNotificationsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
      state.data.type = action?.payload?.type || null;
      state.data.notificationType = action?.payload?.notification_type || null;
      state.data.status = action?.payload?.status || null;
      state.data.startDate = action?.payload?.start_date;
      state.data.endDate = action?.payload?.end_date;
    },
    clearNotificationsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
      state.data.type = null;
      state.data.notificationType = null;
      state.data.status = null;
      state.data.startDate = null;
      state.data.endDate = null;
    },
    requestGeneric: (state) => {
      state.group.loading = true;
      state.group.error = null;
    },
    receiveGenericList: (state, action) => {
      state.group.loading = false;
      state.group.data = action?.payload?.content;
    },
    receiveGenericError: (state, action) => {
      state.group.loading = false;
      state.group.error = action.payload;
    },
    clearGenericData: (state) => {
      state.group.loading = false;
      state.group.error = false;
      state.group.data = [];
    },
  },
});

const Actions = notificationsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.notifications,
  notificationLoading: ({ notifications: { loading } }) => ({ loading }),
};

const Async = {
  fetchNotificationsList: () => async (dispatch, getState) => {
    const {
      notifications: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
          type,
          notificationType,
          status,
          startDate,
          endDate,
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestNotifications());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: NOTIFICATIONS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
          type: type?.toUpperCase(),
          notification_type: notificationType?.toUpperCase(),
          status: status?.toUpperCase(),
          start_date: startDate,
          end_date: endDate,
        },
      });

      action = Actions.receiveNotificationsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveNotificationsError(e.message);
      }
    }

    action && dispatch(action);
  },

  createNotification:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestNotifications());
      try {
        if (!data.image) delete data.image;

        const notificationParsed = parseNotificationTest(data);
        const notificationsData = formDataFromObj(notificationParsed);

        const response = await request({
          method: 'POST',
          url: NOTIFICATIONS_CREATE_URL,
          data: notificationsData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveNotificationsError());
        onError(e);
      }
    },

  getNotificationsById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${NOTIFICATION_BY_ID_URL}/${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteNotifications:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'DELETE',
          url: `${NOTIFICATIONS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  updateNotification:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestNotifications());
      try {
        if (!(data.image instanceof File)) delete data.image;

        const notificationParsed = parseNotificationTest(data);
        const notificationsData = formDataFromObj(notificationParsed);

        const response = await request({
          method: 'PUT',
          url: NOTIFICATIONS_UPDATE_URL,
          data: notificationsData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveNotificationsError());
        onError(e);
      }
    },

  fetchGenericList: (type) => async (dispatch) => {
    if (type) {
      defaultRequest(
        dispatch,
        type,
        'GET',
        Actions.requestGeneric,
        Actions.receiveGenericList,
        Actions.receiveGenericError
      );
    }
  },
};

const { reducer } = notificationsSlice;

export { reducer, Actions, Async, Selectors };
