import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Selectors } from 'modules/Users/Students/StudentLicenseSlice';
import { Formik } from 'formik';
import i18n from 'i18next';

import {
  Row,
  Col,
  Label,
  FormGroup,
  Icon,
  Button,
  Title,
  Loader,
  Switch,
  MUIDatePicker,
  SelectAutocomplete,
} from 'modules/Core/Common';
import { licenseTypes } from 'modules/Licenses/LicenseUtils';

import LicenseAmount from 'modules/Licenses/LicenseAmount';
import { LicenseRequirements } from 'modules/Licenses/LicenseRequirements';

import * as S from './styles';

import { createStudentLicenseModel } from '../StudentUtils';
import StudentLicenseSchema from '../StudentLicenseSchema';

const StudentLicenseForm = ({
  studentId,
  studentLicense,
  onSubmit,
  btnCloseClick,
}) => {
  const { loading } = useSelector(Selectors.studentLicenseLoading);
  const isCustomLicense = studentLicense?.custom_test?.id;
  const formRef = useRef(null);
  const studentLicenseFields = studentLicense
    ? createStudentLicenseModel(studentLicense)
    : createStudentLicenseModel();

  const handleHasAntifraudChange = (
    event,
    setFieldValue,
    handleFormikChange
  ) => {
    setFieldValue('photoStudent', event.target.checked);
    setFieldValue('photoUploadId', event.target.checked);
    setFieldValue('photoUploadType', event.target.checked ? 'ID' : '');
    setFieldValue('screenShare', event.target.checked);
    setFieldValue('takePictureNextId', event.target.checked);
    setFieldValue('roomScan360', event.target.checked);
    setFieldValue('roomScanRandom', event.target.checked);
    handleFormikChange(event);
  };

  return (
    <>
      <Formik
        initialValues={studentLicenseFields}
        validationSchema={StudentLicenseSchema}
        onSubmit={(data) => onSubmit(data)}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <S.Form onSubmit={handleSubmit} noValidate>
            <S.FormHeader>
              <Title size="secondary">
                {studentLicense.id
                  ? i18n.t(
                      'modules.users.students.student-form.form-header.title.edit-student-license'
                    )
                  : i18n.t(
                      'modules.users.students.student-form.form-header.title.add-student-license'
                    )}
              </Title>
              <S.FormHeaderActions>
                <Button
                  medium
                  type="submit"
                  data-test="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Loader
                      width="24px"
                      borderWidth="2px"
                      text="Please wait..."
                    />
                  ) : (
                    i18n.t(
                      'modules.users.students.student-form.form-header.buttons.save-license'
                    )
                  )}
                </Button>
                <S.ButtonClose type="button" onClick={btnCloseClick}>
                  <Icon fontSize="16px" name="close" />
                </S.ButtonClose>
              </S.FormHeaderActions>
            </S.FormHeader>
            <S.FormBody ref={formRef}>
              <Row>
                <Col xs={12}>
                  <S.AntiFraudContainer>
                    <Label>
                      {i18n.t(
                        'modules.licenses.license-forms.license-inputs.has-e-Proctoring'
                      )}
                    </Label>
                    <Switch
                      id="hasAntiFraud"
                      name="hasAntiFraud"
                      active={values.hasAntiFraud}
                      onChange={(event) =>
                        handleHasAntifraudChange(
                          event,
                          setFieldValue,
                          handleChange
                        )
                      }
                    />
                  </S.AntiFraudContainer>
                </Col>

                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="name">
                      {i18n.t(
                        'modules.licenses.license-forms.license-inputs.expiration-date'
                      )}
                    </Label>

                    <MUIDatePicker
                      inputClick
                      placeholder={i18n.t(
                        'modules.licenses.license-forms.license-inputs.expiration-date'
                      )}
                      textFieldProps={{
                        placeholder: 'Expiration Date',
                      }}
                      date={values.expirationDate}
                      onChange={(newValue) =>
                        newValue && setFieldValue('expirationDate', newValue)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup>
                    <Label htmlFor="licenseType">
                      {i18n.t(
                        'modules.licenses.license-forms.license-inputs.license-type'
                      )}
                    </Label>

                    <SelectAutocomplete
                      id="licenseType"
                      name="licenseType"
                      searchPlaceholder="Search"
                      options={licenseTypes}
                      disabled={isCustomLicense || values.license_id}
                      errors={errors.licenseType}
                      touched={touched.licenseType}
                      containerElement={formRef?.current}
                      value={[values.licenseType]}
                      placeholder="Select license Type"
                      onChangeSelect={(id) => setFieldValue('licenseType', id)}
                    />
                  </FormGroup>
                </Col>
                {values.hasAntiFraud && (
                  <Col xs={12}>
                    <LicenseRequirements
                      data={values}
                      onChange={(param, value) => setFieldValue(param, value)}
                    />
                  </Col>
                )}
              </Row>

              <LicenseAmount
                studentId={studentId}
                license={values}
                handleChangeValue={setFieldValue}
                showLicensesAvailable={false}
                testType={
                  values.testId
                    ? 'base_test'
                    : values.customTestId
                    ? 'custom_test'
                    : ''
                }
                onCustomCategoriesAmountChange={(customCategoriesAmount) =>
                  setFieldValue(
                    'customCategoriesAmount',
                    customCategoriesAmount
                  )
                }
              />
            </S.FormBody>
          </S.Form>
        )}
      </Formik>
    </>
  );
};

StudentLicenseForm.propTypes = {
  studentLicense: PropTypes.object,
  studentId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  btnCloseClick: PropTypes.func.isRequired,
};

export default StudentLicenseForm;
