export const ZIndexes = {
  fullscreen: 10,
  modal: 9,
  modalResults: 5,
  envIdentifier: 5,
  submenuMobile: 8,
  feedback: 8,
  submenu: 6,
  evaluatorBar: 6,
  sidebar: 7,
  header: 3,
  overlay: 1,
  audioDownload: 98,
  loading: 99,
  installModal: 100,
};
